header.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 1.25rem;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  img.logo {
    width: 215px;
  }

  & footer {
    padding: 1rem 0 0;
  }

  & > div.empty {
    width: 215px;
    height: 0;
  }
}
