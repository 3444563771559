section.finish {
  & .finish-logo {
    margin-top: 1rem;
    width: 400px;
  }

  & .finish-box {
    margin: 1.5rem;
  }

  header {
    text-align: center;

    h1 {
      margin-top: 2rem;

      text-transform: uppercase;
      font-size: 2.75rem;
      font-weight: bold;
    }

    p {
      font-size: 1.75rem;
    }
  }
}
