section.login {
  position: relative;
  margin-top: 2.5rem;
  min-width: 500px;
  max-width: 600px;

  & .login-box {
    position: relative;
    margin: 0;
    padding: 0;
    width: auto;

    img {
      width: 100%;
    }

    form {
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      padding: 2rem;

      border-radius: 25px;

      text-align: center;

      background-color: white;
    }
  }
}
