section.waiting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img.waiting-logo {
    margin: 3rem 0;
    width: 400px;
  }

  & .waiting-box {
    position: relative;
    margin: 0;
    padding: 0;
    width: auto;

    img {
      width: 100%;
      max-width: 600px;
    }
  }

  header {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;

    background-color: #fff;
    padding: 2rem;
    border-radius: 25px;
    text-align: center;
    font-size: 1.25rem;

    h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.75rem;
    }
  }
}
