fieldset {
  padding-bottom: 2rem;
  margin: 2rem;
  border-bottom: 1px solid rgb(92, 92, 92);
  transition: all 500ms ease;

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }

  &.error .field .checkbox {
    --color: red;
  }

  &.error p.help-text {
    color: red;
    font-weight: bold;
  }

  & legend {
    margin-bottom: 1.5rem;
    font-size: 1.15rem;
    font-weight: bolder;
  }
}

.checkbox {
  --color: currentColor;
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 1.15rem;
  color: var(--color);
}

.checkbox__control {
  display: inline-grid;
  width: 1em;
  height: 1em;
  border-radius: 0.25em;
  border: 0.1em solid currentColor;

  svg {
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
  }
}

.checkbox__input {
  display: grid;
  grid-template-areas: 'checkbox';

  > * {
    grid-area: checkbox;
  }

  input {
    opacity: 0;
    width: 1em;
    height: 1em;

    &:focus + .checkbox__control {
      box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em currentColor;
    }

    &:checked + .checkbox__control svg {
      transform: scale(1);
    }
  }
}

ul {
  margin-left: 1.5rem;
  list-style-type: circle;
}
