.results {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .results-question {
    margin-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  header h1 {
    font-size: larger;
    font-weight: bold;
    text-align: center;
  }

  article > div {
    margin-top: 1.2rem;
  }

  table {
    margin: 1.5rem auto;
  }

  .table-caption {
  }
}
